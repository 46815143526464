import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Grid } from '@material-ui/core'
import { StorageChart, Lastupdate, MenuComponent, Icon } from '../../../components'
import { DispatchContext } from "../../../reducers";
import { list2 } from '../../../services/Api'

const allOptions = {
    onshore: [
        { name: 'onshore:us', label: 'US' },
        { name: 'onshore:ara', label: 'ARA' },
        { name: 'onshore:fujairah', label: 'Fujairah' },
    ], 
    floating: [
        { name: 'floating:crude', label: 'Crude/Condensate' },
        { name: 'floating:lpg', label: 'LPG' },
        { name: 'floating:naphtha', label: 'Naphtha' },
        { name: 'floating:gasoline', label: 'Gasoline' },
        { name: 'floating:gasoil', label: 'Gasoil/Diesel' },
        { name: 'floating:jet', label: 'Jet/Kero' },
        { name: 'floating:fo', label: 'Fuel Oil' },
    ]
}

export const Storage = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState()
    const [options, setOptions] = useState()
    const [option, setOption] = useState()
    
    const fetch = async () => {
        if (!type || !option) return
        dispatch({ type: 'spin on' })
        const data = await list2(`storage/${type}`, option.name )
        dispatch({ type: 'spin off' })
        setData(data)
    }

    useEffect(() => {
        setOption(null)
        setOptions(allOptions[type])
    }, [type])

    useEffect(() => {
        if (options) setOption(options[0])
    }, [options])

    useEffect(() => {
        if (option) fetch()
    }, [option])

    const onSelect = (event) => {
        setOption(event)
    }

    return (
        <div className='column-center'>
            <div className='w-90p row-between'>
                <Lastupdate type={`storage:${type === 'onshore' ? 'onshore:eia' : type}`} align='left' />
                <MenuComponent label={<Icon icon='faCaretSquareDown' size='sm' />} options={options} callback={onSelect} />
            </div>
            <Grid container className='row-center'>
                {data && data.map(({ name, config, data }) => 
                    <Grid key={`key_${type}_storage_${name}`} item md={6} className='row-center w-full'>
                        <StorageChart data={data} title={name} controls={config.controls} type={type} />
                    </Grid>
                )}       
            </Grid>
        </div>
    )
}