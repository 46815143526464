import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { colors, post2 } from '../../services/Api'
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const PieChartProduct = ({ data, width, height }) => {

    const [options, setOptions] = useState({})

    useEffect(() => {
        const options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                width,
                height
            },
            exporting: {
                buttons: {
                    contextButton: {
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            },
            title: {
                text: '',
            }, 
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    colors,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        distance: -50,
                        format: '{point.y}%',
                        connectorColor: 'silver'
                    },
                    showInLegend: true
                }
            },
            series: [
                {
                    name: '',
                    data
                }
            ]
        }
        setOptions(options)
    }, [data, width, height]);

    return (<div className='row-center'>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}