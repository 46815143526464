import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Grid, Modal, Backdrop, Fade } from '@material-ui/core'
import { list2 } from '../../../services/Api'
import { InterestChart, InterestHistoryChart, InterestGrid, Lastupdate } from '../../../components';
import { DispatchContext } from "../../../reducers";
import { useWindow } from '../../../hooks'

export const OpenInterest = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const size = useWindow()
    const dispatch = useContext(DispatchContext)
    const [oiData, setOiData] = useState()
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState()
    
    useEffect(() => {
        async function fetch () {
            if (!type) return
            dispatch({ type: 'spin on' })
            const result = await list2('openInterest', type)
            dispatch({ type: 'spin off' })
            setOiData(result)
        }
        fetch()
    }, [type])

    const onCallback = (param) => {
        setSelected(param)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setSelected(null)
    }

    return (
        <div className='column px-1 mb-6'>
            <div className='row-right pr-2'>
                <Lastupdate type='openInterest' />
            </div>
            { oiData && oiData.map(({ type, name, chartData, gridData }, index) => {
                return (
                    <Grid container className='row-center my-2' key={`key_open_interest_chart_${type}_${name}`}>
                        <Grid item md={6} className='row-center w-full'>
                            <InterestChart chartData={chartData} index={index} />
                        </Grid>
                        <Grid key={`key_open_interest_grid_${type}_${name}`} item md={6} className='row-center w-full'>
                            <InterestGrid data={gridData} name={name} callback={(param) => onCallback(param)} />
                        </Grid>
                    </Grid>
                )}
            )}
            <div className='mb-6'></div>
            <Modal open={open} onClose={handleClose} className='modal' closeAfterTransition 
                BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={open}>
                    <div className='modal__curve' style={{ width: '90%', height: size.height*0.7, maxWidth: size.width*0.8 }} >
                        {selected && <div className='column-center p-2'>
                            <InterestHistoryChart param={selected} />
                        </div>}
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}