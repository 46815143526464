import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useWindow } from '../../hooks'
import { singleChartWidth, chartHeight, post2 } from '../../services';
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const ProfileChartNetRow = ({ data }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        if (!data) return
        const { categories, series, title, subtitle } = data
        const options = {
            chart:  { 
                type: 'bar',
                width: singleChartWidth(size.width),
                height: chartHeight(size.height)/2,
            },
            exporting: {
                buttons: {
                    contextButton: {
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            },
            title,
            subtitle,
            xAxis: {
                categories
            },
            yAxis: {
                title: {
                    text: 'mbd'
                }
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series
        }
        setOptions(options)
    }, [data, size]);

    return (<div className='row-center'>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}