import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { gridHeight } from '../../services/Api';
import { userStatusRenderer } from '.'

export const UserGrid = ({ data }) => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState([])
    const [columnDefs, setColumnDefs] = useState([])

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        sizeToFit();
        window.onresizable = () => {
            autoSizeAll()
        }
    };
  
    const sizeToFit = () => {
        if (gridApi) gridApi.sizeColumnsToFit();
    };

    const autoSizeAll = (skipHeader) => {
        if (gridColumnApi) {
            const allColumnIds = [];
            gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
        }
    };

    const callback = (param) => {
        // console.log(param)
    }

    useEffect(() => {
        if (data) {
            setColumnDefs([
                { field: 'name', headerName: 'Name' },
                { field: 'region', headerName: 'Office', flex: 1, cellStyle: { textAlign: 'center' } },
                { field: 'username', headerName: 'Email', flex: 3, cellStyle: { fontSize: '11px', color: 'blue' }, minWidth: 200 },
                { field: 'group', headerName: 'Group',cellStyle: { fontSize: '11px' } },
                { field: 'subGroup', headerName: 'Sub-Group', cellStyle: { fontSize: '11px' } },
                { field: 'status', headerName: 'Status', flex: 1, cellRenderer: 'cellRenderer', cellRendererParams: param => ({ param, callback }) },
            ])
            setRowData(data)
        }
    }, [data])
    
    return (
        <div className="column-center w-full">
            <div className="ag-theme-alpine ag-center" style={{ height: gridHeight(rowData.length), width: '99%' }}>
                <AgGridReact rowData={rowData} columnDefs={columnDefs} onGridReady={onGridReady} 
                    defaultColDef={{
                        flex: 2,
                        minWidth: 100,
                        suppressMovable: true,
                        filter: true,
                        sort: true,
                        cellStyle: { textAlign: 'left' }
                    }}
                    frameworkComponents={{ cellRenderer: userStatusRenderer }}
                />
            </div>
        </div>
    );
};