import { useState, useContext, useRef } from "react";
import { Button, TextField } from '@material-ui/core';
import { DispatchContext } from "../reducers";
import { login, forgetPassword, IsValidEmail } from "../services";
import background from '../pics/login.png'
import { Message } from '../components';
import { useHistory, useParams } from "react-router-dom";

export const Login = () => {
    const history = useHistory()
    const { path } = useParams()
    const dispatch = useContext(DispatchContext)
    const messageRef = useRef()
    const [forget, setForget] = useState(false)
    const [username, setUsername] = useState()

    // const [open, setOpen] = useState(false)
    // const [password, setPassword] = useState()

    // const onClose = () => {
    //     setOpen(false)
    // }
    const handleUsername = (event) => {
        setUsername(event.target.value)
    }
    // const handlePassword = (event) => {
    //     setPassword(event.target.value)
    // }
    // const onConfirm = async () => {
    //     dispatch({ type: 'spin on' })
    //     const result = await login({ username, password })
    //     dispatch({ type: 'spin off' })
    //     setOpen(false)
    //     if (result === true) {
    //         messageRef.current.showSuccess('Login Success')
    //         window.location.reload();
    //     } else {        
    //         messageRef.current.showError(result.error)
    //     }
    // }

    // const onLogin = () => {
    //     setOpen(true)
    // }

    const onLogin = async () => {
        dispatch({ type: 'spin on' })
        await login()
        if(path)
            history.push(`/${path}`) 
        dispatch({ type: 'spin off' })
    }

    const onForget = async () => {
        setForget(true)
    }

    const onForgetPassword = async () => {
        if (!username) {
            messageRef.current.showError('Email is empty')
            return 
        } else if (!IsValidEmail(username)) {
            messageRef.current.showError('Incorrect email format')
            return 
        }
        const { error } = await forgetPassword(username)
        if (error) {
            messageRef.current.showError(error)
        } else {
            messageRef.current.showSuccess(`Request sent. Check your email to reset password`)
            setUsername(null)
            setForget(false)
        }
    }
    const onKeyPress= (event) => {
        if (event.code === 'Enter') {  
            onForgetPassword()
        }
    }

    const LoginButton = () => {
        return <div className='light_gray mx04'><Button variant='outlined' onClick={() => onLogin()}>Login</Button ></div>;
    };

    const GuestButton = () => {
        return <div className='login__card__action__button'><Button variant='outlined' disabled={true} onClick={() => onLogin()}>Guest</Button ></div>;
    };

    return (
        <div style={{ 
                backgroundImage: `url(${background})`, 
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-start',
            }}>
            <Message ref={messageRef} />
            <div className='mr-3 mt-3 f-10 bold-5 green2'>
                ATC ANALYTICS
            </div>
            <div className='login-button w-full column-center'>
                {!forget && <div className='column-center mb-2'>
                    <LoginButton />
                    <div className='mt-2'><Button variant='text' size='small' onClick={onForget}><div className='dark_blue'>Forget Password</div></Button ></div>
                </div>}
                {forget && <div className='column-center'>
                    <TextField variant='outlined' size='small' placeholder='Email' className='w-300' onChange={handleUsername} onKeyPress={onKeyPress} inputProps={{ style: { textTransform: "lowercase" } }}/>
                    <div className='mt-2 row-between w-200'>
                        <Button variant='text' size='small' onClick={() => setForget(false)}><div className=''>Back</div></Button >
                        <Button variant='text' size='small' onClick={onForgetPassword}><div className='dark_blue'>Submit</div></Button >
                    </div>
                </div>}
            </div>
            {/* <Dialog onClose={onClose} open={open}>
                <div className='column-center f-02 w-300 h-200'>
                    <div className="mb-3">
                        <TextField variant='outlined' placeholder='Email' className='w-95p' onChange={handleUsername} />
                    </div>
                    <div>
                        <TextField variant='outlined' type='password' placeholder='Password' className='w-95p' onChange={handlePassword} />
                    </div>
                </div>
                <div className='row-between px-3 my-3'>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={onConfirm}><label className='dark_blue bold-5'>Login</label></Button>
                </div>
            </Dialog> */}
        </div>
    )
}