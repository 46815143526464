import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useWindow from '../../hooks/useWindow'
import { singleChartWidth, chartHeight, post2 } from '../../services';
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const LaycanChart = ({ data }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        const { series, title, subtitle, categories } = data
        const options = {
            chart:  { 
                type: 'column',
                width: singleChartWidth(size.width),
                height: chartHeight(size.height, 200)
            },
            exporting: {
                buttons: {
                    contextButton: {
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            },
            title: { text: title },
            subtitle: { text: subtitle },
            xAxis: { title: { text: '' }, categories },
            yAxis: {
                title: { text: '' },
                stackLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'allow',
                    style: {
                        fontWeight: 'bold',
                        color: 'gray'
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: { enabled: false }
                }
            },
            series
        }
        setOptions(options)
    }, [data, size]);

    return (<div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}