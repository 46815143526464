
import { useState, useEffect } from 'react';
import { Icon, SentimentBar } from '../../../components'
import soundWaveGif from '../../../pics/sound-wave.gif'

export const NewsSum = ({ playing: plying, exp, callback, news: { id, title, source, date, json, summarization, byline, dateline, fullStoryOnly, positive, negative, neutral } }) => {

    const [paragraph, setParagraph] = useState([])
    const [summary, setSummary] = useState(summarization)
    const [full, setFull] = useState(false)
    const [playing, setPlaying] = useState(false)

    useEffect(() => {
        if (full) {
            setParagraph(json.paragraph)
        } else {
            setParagraph(null)
        }
    }, [json, full])

    useEffect(() => {
        if (fullStoryOnly) {
            setFull(true)
        } else {
            setSummary(summarization || 'Auto generation of summary in progress')
        }
    }, [fullStoryOnly, summarization])

    useEffect(() => {
        setPlaying(plying)
    }, [plying])

    const handleFull = () => {
        setFull(!full)
    }

    const goTop = (event) => {
        event.preventDefault()
        document.getElementsByName('_top')[0].scrollIntoView(0, -200)
    }

    const onPlay = () => {
        callback({ title, paragraph, id })
    }

    const onStop = () => {
        callback({ title, paragraph, id, stop: true })
    }

    return (
        <div className='column-start px-1 pb-6'>
            <div className='mb-6'>
                <label className='ft-news-title'>{title}</label>
            </div>
            <div className='row-between ft-news'>
                <label className='f-01 bold gray'>{`${full && !(fullStoryOnly && source === 'Reuters') ? `Source: ${source}` : 'NLP Summary'}`}</label>
                <label className='f-01 bold gray'>{date}</label>
            </div>
            {exp === true && positive > 0 && <div className='row-start'>
                <SentimentBar sentiment={{ positive, negative, neutral }} />
            </div>}
            {!paragraph && <div className='mt-6 mb-4 ft-news line-height-news'>
                {summary}
            </div>}
            {!fullStoryOnly && paragraph && <div className='mt-6 row-between mb-4'>
                <div className='fs-16 underline aramco_blue' onClick={handleFull}>
                    Summary
                </div>
                {exp === true && !playing && <div onClick={onPlay} className='mr-2 green4 f-08 h-30'><Icon icon='faVolumeUp' /></div>}
                {exp === true && playing && <div onClick={onStop} className='mr-2 h-30'><img src={soundWaveGif} alt="sound-wave" width={26} height={26} /></div>}
                {exp !== true && <div></div>}
            </div>}
            {full && (dateline || byline) && <div className="my-4 ft-news line-height-news row-left">
                <label className='mr-4'>{byline}</label>
                <label className=''>{dateline}</label>
            </div>}
            {paragraph && paragraph.map((text, i) => <div key={`key_news_json_paragraph_${i}`} className='mt-4 ft-news line-height-news'><label className=''>{text}</label></div>)}
            <div className='row-between mt-4 aramco_blue'>
                {!fullStoryOnly && json && <div className='f-02 row'>
                    <div className='underline' onClick={handleFull}>{paragraph ? 'Summary' : 'Full Story'}</div>
                </div>}
                {(fullStoryOnly || !json) && <div>&nbsp;</div>}
                <div className='' onClick={goTop}><Icon icon='faArrowAltCircleUp' size='1x' /></div>
            </div>
        </div>

    )
}