import { AdvancedChart } from "react-tradingview-embed";
import { useState, useEffect, useContext } from 'react'
import { useParams } from "react-router-dom";

export const TradingViewModel = () => {

    return (
        <div style={{ 
            width: '100vw',
            height: '82vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
        }}>
            <div style={{ width: '100%', height: '80%' }}>
            <AdvancedChart widgetProps={{
        "container_id": "analytics-platform-chart-demo",
        "width": "100%",
        "height": "100%",
        "autosize": true,
        "symbol": "ICEEUR:BRN1!",
        "interval": "60",
        "timezone": "Asia/Singapore",
        "theme": "light",
        "style": "1",
        "toolbar_bg": "#f1f3f6",
        "hide_side_toolbar": false,
        "withdateranges": true,
        "allow_symbol_change": true,
        "save_image": true,
        "studies": [
        "BB@tv-basicstudies",
        "MACD@tv-basicstudies",
        "RSI@tv-basicstudies"
        ],
        "locale": "en"
    }} />;

            </div>
        </div>
    )
}


