import { useState, useEffect, useContext } from 'react';
import { DispatchContext } from "../../../reducers";
import { Pdf } from '../../../components';
import { list2 } from '../../../services/Api';

export const ReportItem = ({ dirname, filename, label }) => {
    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState()
    
    useEffect(() => {
        async function fetch() {
            dispatch({ type: 'spin on' })
            const { data } = await list2(`report/download/${dirname}`, filename)
            dispatch({ type: 'spin off' })
            setData(data)
        }
        fetch()
    }, [])

    return (
        <div>
            { data && <Pdf data={data} filename={label} />}
        </div>
    );
}
