import { useState, useEffect, useContext } from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import moment from 'moment'
import { Lastupdate, ProductionChart } from '../../../components'
import { DispatchContext } from "../../../reducers";
import { list2 } from '../../../services/Api'
import { ProductionFilter } from '.'

export const ProductionModel = () => {

    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState()

    const [conditions, setConditions] = useState({ split: 'country', country: ['Saudi Arabia'], grade: [], start: moment().subtract(8, 'month').format('YYYY-MM-01'), end: moment().add(3, 'month').format('YYYY-MM-01') })
    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const callback = event => {
        setConditions(event)
        setExpanded(false)
    }

    useEffect(() => {
        async function fetch () {
            if (conditions) {
                dispatch({ type: 'spin on' })
                const production = await list2('production', null, conditions)
                dispatch({ type: 'spin off' })
                setData(production)
            }
        }
        fetch()
    }, [conditions])

    return (
        <div>
            <Accordion expanded={expanded === 'panel'} className={`w-95p ${expanded === 'panel' ? '' : 'box-shadow-0'}`} onChange={handleChange('panel')}>
                <AccordionSummary expandIcon={<FilterList />}>
                    <Lastupdate type='production' align='left'/>
                </AccordionSummary>
                <AccordionDetails className='column'>
                    <ProductionFilter init={conditions} callback={callback} />
                </AccordionDetails>
            </Accordion>
            <div className="mt-3 row-center">
                {data && conditions && <ProductionChart data={data} conditions={conditions} />}
            </div>
        </div>
    )
}