import { Button } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import useWindow from '../hooks/useWindow'
import { ExportFile, Icon } from '.';

export const Pdf = ({ data, filename }) => {
    const [file, setFile] = useState();
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [loadSuccess, setLoadSuccess] = useState(false);
    const [scale, setScale] = useState(1.0);
    // const [scaling, setScaling] = useState(false);
    // const [logger, setLogger] = useState('');
    const size = useWindow()

    useEffect(() => {
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: 'application/pdf' });
        setFile(blob)
    }, [data])
  
    const onDocumentLoadSuccess = ({ numPages }) => {
        setLoadSuccess(true)
        setNumPages(numPages);
    }

    const onPageUp = () => {
        const num = pageNumber < numPages ? pageNumber + 1 : numPages
        setPageNumber(num)
    }
  
    const onPageDown = () => {
        const num = pageNumber > 1 ? pageNumber - 1 : 1
        setPageNumber(num)
    }

    const onScaleUp = () => {
        const newScale = +(scale + 0.5).toFixed(1)
        setScale(newScale)
    }
  
    const onScaleDown = () => {
        const newScale = +(scale - 0.5).toFixed(1)
        setScale(newScale)
    }
  
    const onScaleNormal = () => {
        setScale(1.0)
    }

    // const onWheel = (event) => {
    //     const { deltaY } = event
    //     const newScale = (scale >= 4 && deltaY < 0) ? 4.0 : (scale <= 0.5 && deltaY > 0) ? 0.5 : +(scale + (-0.005 * deltaY)).toFixed(1)
    //     setScale(newScale)
    // }
  
    // const onTouchStart = (event) => {
    //     console.log('start', event.touches)
    //     const { touches } = event

    //     if (event.touches.length === 1) {
    //         setScaling(true)
    //         setLogger(JSON.stringify(event.touches[0]))
    //     } else {
    //         setLogger('not two touches')
    //     }
    // }
  
    // const onTouchEnd = (event) => {
    //     setScaling(false)
    //     setLogger('')
    //     // console.log('end', event)
    // }
  
    // const onTouchMove = (event) => {
    //     if (scaling) {
    //         setLogger(JSON.stringify(event))
    //     } else {
    //         setLogger('not scaling')
    //     }
    //     //onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} onTouchMove={onTouchMove}
    // }

    return (
        <div className=''>
            {/* <div>{logger}</div> */}
            {loadSuccess && <div className='row-center bg-dark_gray white'>
                <ExportFile data={[{ file: data, filename, blob: file, type: 'application/pdf' }]} subject={filename} type='report' color='white' />
                <Button onClick={onPageDown}><Icon icon={'faChevronLeft'} size='1x' color='white' /></Button>
                <label className='f-01'>{pageNumber} of {numPages}</label>
                <Button onClick={onPageUp}><Icon icon={'faChevronRight'} size='1x' color='white' /></Button>
                <Button onClick={onScaleUp} disabled={scale >= 4.0}><Icon icon={'faSearchPlus'} size='1x' color='white' /></Button>
                <Button onClick={onScaleNormal}><label className='white f-01'>{(+scale * 100).toFixed(0)}%</label></Button>
                <Button onClick={onScaleDown} disabled={scale <= 0.5}><Icon icon={'faSearchMinus'} size='1x' color='white' /></Button>
            </div>}
            <div className='w-full'>
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess} loading='Loading PDF file...' >
                    <Page pageNumber={pageNumber} width={Math.min(size.width, 1200)} scale={scale} />
                </Document>
            </div>
        </div>
    );
}
