import { Item } from '.'

export const Group = ({ subscriptions }) => {
    return (
        <div className=''>
            {subscriptions.map(subscription => {
                const { category, type, subtitle } = subscription
                return (
                    <Item key={`subscription_item_${category}_${type}`} category={category} type={type} subtitle={subtitle} />
                )
            })}
        </div>
    )
}