
import React, { useState, useEffect, useContext } from 'react';
import { list2 } from '../../../services'
import { RefineryChart, ExportFile } from '../../../components'
import { DispatchContext } from "../../../reducers";
import moment from 'moment';

export const RefineryComponent = ({ type, conditions }) => {

    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState()
    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const { unit, region, subRegion, country, start, end } = conditions
    const [subject, setSubject] = useState()
    const [csvData, setCsvData] = useState()
    const unit_groups_using_kt = ['CHEM ETHYLENE', 'CHEM PDH', 'MOG MTBE']

    useEffect(() => {
        async function fetch () {
            if (!type || !unit) return
            const title = `${region === 'All' ? 'Global' : region.name}${subRegion === 'All' ? '' : ` - ${subRegion.name}`}${country === 'All' ? '' : ` - ${country.name}`}`
            const uom = unit_groups_using_kt.includes(unit) ? 'KT' : 'MBD'
            setTitle(title)
            setSubtitle(type.toLowerCase() === 'runs' ? `Runs (MBD)` : `${unit} (${uom})`)
            const query = { start, end }
            if (region !== 'All') query.regionId = region.id
            if (subRegion !== 'All') query.subRegionId = subRegion.id
            if (country !== 'All') query.countryId = country.id
            dispatch({ type: 'spin on' })
            const data = await list2(`${type}/year`, unit, query)
            dispatch({ type: 'spin off' })
            setData(data)
            const { lines, stackColumns } = data
            const years = lines.map(({ name, data })=> ({ '': `${data[0].date === data[data.length - 1].date ? name : `${data[0].date.slice(0, 4)}-${data[data.length - 1].date.slice(0, 4)}`}`, ...data.reduce((pre, dat) => ({ ...pre, [moment(dat.date).format('MMM')]: dat.y }), {}) }))
            const stacksCurrent = stackColumns ? [{ '': stackColumns.name }] : []
            const stacks = stackColumns ? stackColumns.data.map(({ name, data })=> ({ '': name, ...data.reduce((pre, dat, i) => ({ ...pre, [moment(dat.date).format('MMM')]: dat.y }), {}) })) : []
            const processed = [...years, ...stacksCurrent, ...stacks]
            const sub = region === 'All' ? 'Global' : subRegion === 'All' ? region.name : country === 'All' ? subRegion.name : country.name
            const filename = `Refinery ${type} ${sub}${type === 'Runs' ? '' : ` ${unit}`} ${moment().format('YYYY-MM-DD')}.csv`
            setSubject(filename)
            setCsvData([{ filename, data: processed, type: 'text/csv' }])

        }
        fetch()
    }, [type, unit, region, subRegion, country, start, end])

    return (
        <div className="">
            <div className='z999 ml-2 row-right'>
                <ExportFile data={[]} type={type.toLowerCase()} subject={subject} />
            </div>
            <div className=''>
                {data && <RefineryChart data={data} title={title} subtitle={subtitle} yaxis={type} />}
            </div>
        </div>
    )
}

export const RefineryItem = ({ type }) => {
    return <RefineryComponent type={type} conditions={{ unit: 'CDU', region: 'All', subRegion: 'All', country: 'All' }} />
}