import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import useWindow from '../../hooks/useWindow'
import { singleDoubleChartWidth, chartHeight, post2 } from '../../services';
import { colors } from '../../services/Api'
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const CurveChart = ({ data, title, isSingle=true }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        const options = {
            chart:  { 
                type: 'spline',
                width: singleDoubleChartWidth(size.width, isSingle),
                height: chartHeight(size.height)
            },
            exporting: {
                buttons: {
                    contextButton: {
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            },
            title: {
                text: title
            },
            yAxis: { title: { text: '' } },
            xAxis: {
                title: { text: '' },
                categories: data && data[0] ? [...Array(12).keys()].map(i => moment(data[0].date).add(i + 1, 'month').format('MMM YY')) : []
            },
            legend:{
                maxHeight: 100
            },
            series: data && data[0] ? data.map((dat, i) => ({
                name: dat.date,
                data: Object.entries(dat).filter(entry => !['id', 'date'].includes(entry[0])).map(entry => entry[1]),
                color: colors[i%7]
            })) : []
        }
        setOptions(options)
    }, [data, title, size, isSingle]);

    return (
    <div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}