import { MarketView } from '../Price'
import { NewsUnit, WindowDealInfoByType, WindowDealReport } from '../News'
import { ForwardCurve } from '../Price'
import { Flows, Fixtures } from '../Snapshot'
import { RefineryItem, SDItem, FlowsModel } from '../Model'

export const Item = ({ category, type, subtitle }) => {
    return (
        <div className=''>
            {subtitle && <label className='subtitle ml-2'>{subtitle}</label>}
            { category === 'market_view' && <MarketView type={type} /> }
            { category.startsWith('News - ') && <NewsUnit type={type} /> }
            { category === 'window_deal_data' && <WindowDealInfoByType type={type} /> }
            { category === 'window_deal_report' && <WindowDealReport type={type} /> }
            { category === 'forward_prices' && <ForwardCurve type={type} /> }
            { category === 'forward_spreads' && <ForwardCurve type={type} /> }
            { category === 'flows_data' && <Flows type={type} showSubBar={false} /> }
            { category === 'fixtures_data' && <Fixtures type={type} showSubBar={false} /> }
            { category === 'refinery_model' && <RefineryItem type={type} /> }
            { category === 'supply_demand_model' && <SDItem type={type} product={subtitle.toLowerCase().replace(`s&d - ${type} - `, '')} /> }
            { category === 'flows_model' && <FlowsModel type={type} />}
        </div>
    )
}