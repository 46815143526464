import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useWindow from '../../hooks/useWindow'
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
import { post2 } from '../../services'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const FlowsAccumulateChart = ({ data }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        const { series, title, subtitle } = data
        const options = {
            chart:  { 
                width: size.width*0.8-10,
                height: size.height*0.7-50,
                spacingRight: 0,
                spacingLeft: 0
            },
            exporting: {
                buttons: {
                    contextButton: {
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            },
            title: {
                text: title,
                useHtml: true,
                align: 'center'
            },
            subtitle: {
                text: subtitle,
                align: 'right'
            },
            xAxis: {
                title: { text: 'Day of the Month' },
                tickInterval: 1
            },
            legend:{
                maxHeight: 150
            },
            yAxis: { title: { text: '' } },
            series
        }
        setOptions(options)
    }, [data, size]);

    return (<div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}