import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useWindow from '../../hooks/useWindow'
import { singleDoubleChartWidth, chartHeight, post2 } from '../../services';
import moment from 'moment'
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const InterestChartJoin = ({ data }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        const options = {
            chart: {
                type: 'column',
                width: singleDoubleChartWidth(size.width, false),
                height: chartHeight(size.height)
            },
            exporting: {
                buttons: {
                    contextButton: {
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            },
            title: { text: 'Open Interest' }, 
            xAxis: { 
                title: { text: '' },
                categories: Object.keys(data[0]).filter(key => key !== 'date')
                //data.map(dat => moment(dat.date).format('MMM YY')) 
            },
            yAxis: { title: { text: '' } },
            legend: { enabled: false },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        format: '{y}'
                    }
                }
            },
            series: data.slice(0,2).map(dat => ({
                name: moment(dat.date).format('MMM YY'),
                data: Object.keys(data[0]).filter(key => key !== 'date').map(key => dat[key])
            }))
        }
        setOptions(options)
    }, [data, size]);

    return (<div className='row-center'>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}