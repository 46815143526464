import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useWindow } from '../../hooks'
import { singleChartWidth, chartHeight, post2 } from '../../services';
import { colors } from '../../services/Api'
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const ProfileChartColumn = ({ data }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        if (!data) return
        const { categories, series, title, subtitle } = data
        const options = {
            chart:  { 
                width: singleChartWidth(size.width),
                height: chartHeight(size.height)/2,
            },
            exporting: {
                buttons: {
                    contextButton: {
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            },
            title,
            subtitle,
            xAxis: {
                title: { text: '' },
                categories
            },
            yAxis: [{
                title: {
                    text: 'mbd'
                },
                left: 40,
                labels: {
                    x: 10
                }
            },{
                title: {
                    text: ''
                },
                labels: {
                    formatter: function() {
                        return `${this.value}%`
                    }
                },
                opposite: true
            }],
            tooltip: {
                formatter: function () {
                    return ['<b>' + this.x + '</b>'].concat(
                        this.points ?
                            this.points.map(function (point) {
                                return `${point.series.name.replace('(LHS)', '').replace('(RHS)', '')}: ${point.y}${point.series.name === 'Level(LHS)' ? ' mbd' : '%'}`;
                            }) : []
                );
            },
            split: true
            },
            series: series.map(({ index, ...rest }) => ({ color: (index || index === 0) ? colors[index] : undefined, yAxis: (index || index === 0) ? 0 : 1, ...rest }))
        }
        setOptions(options)
    }, [data, size]);

    return (<div className='row-center'>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}