import { useState } from 'react';
import { ClickAwayListener, Tooltip } from '@mui/material';

export const SentimentBar = ({ sentiment }) => {
    const { positive, negative, neutral } = sentiment
    const [open, setOpen] = useState(false)
  
    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setOpen(true);
    };
  
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <Tooltip PopperProps={{ disablePortal: true }} onClose={handleTooltipClose} open={open} 
                    title={
                        <div className='f-01 column-left'>
                            <div className='mb-2 w-full'>Sentiment</div>
                            <div className='row-between w-full'><div>Negative: </div><div>{negative.toFixed(4)}</div></div>
                            <div className='row-between w-full'><div>Neutral: </div><div>{neutral.toFixed(4)}</div></div>
                            <div className='row-between w-full'><div>Positive: </div><div>{positive.toFixed(4)}</div></div>
                        </div>
                    } >
                    <div className='row-center h-10 w-100 mt-2' onClick={handleTooltipOpen} onTouchStart={handleTooltipOpen} onMouseEnter={handleTooltipOpen} >
                        <div className='bg-red0 h-full rounded_corner' style={{ width: `${Math.round(negative*100)}%`, marginRight: '1px' }}></div>
                        <div className='bg-orange0 h-full rounded_corner' style={{ width: `${Math.round(neutral*100)}%`, marginRight: '1px' }}></div>
                        <div className='bg-green0 h-full rounded_corner' style={{ width: `${Math.round(positive*100)}%` }}></div>
                    </div>
                </Tooltip>
            </div>
        </ClickAwayListener>
    )
}