
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import moment from 'moment'
import { RefineryFilter, RefineryComponent } from '.'
import { Lastupdate } from '../../../components';

export const RefineryModel = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const [conditions, setConditions] = useState({ unit: 'CDU', region: 'All', subRegion: 'All', country: 'All', start: moment().format('YYYY-01-01'), end: moment().add(12, 'month').format('YYYY-MM-01') })
    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const callback = event => {
        setConditions(event)
        setExpanded(false)
    }

    return (
        <div className="column-center">
            <Accordion expanded={expanded === 'panel'} className={`w-95p ${expanded === 'panel' ? '' : 'box-shadow-0'}`} onChange={handleChange('panel')}>
                <AccordionSummary expandIcon={<FilterList />}>
                    <Lastupdate type={`refinery:${type.toLowerCase()}`} align='left'/>
                </AccordionSummary>
                <AccordionDetails className='column'>
                    <RefineryFilter type={`refinery:${type.toLowerCase()}`} init={conditions} callback={callback} />
                </AccordionDetails>
            </Accordion>
            <div className='row-center mt-3'>
                <RefineryComponent type={type} conditions={conditions} />
            </div>
        </div>

    )
}