import React, { useState, useEffect, useRef } from 'react';
import { Button, Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, Grid } from '@material-ui/core';
import moment from 'moment'
import { list2, pascal, sleep } from '../../../services'
import { Message } from '../../../components'
import { usePrevious, useWindow } from '../../../hooks'

const levels = ['region', 'sub_region', 'country']
const starts = [...Array(6).keys()].flatMap((i) => {
    const year = moment().subtract(i, 'year').format('YYYY')
    return [...Array(12).keys()].map((e) => moment(`${year}${12 - e}`, 'YYYYM').format('YYYY-MM-01'))
})
const flows = ['import', 'export']
const splits = ['origin', 'destination', 'grade']

export const FlowsFilter = ({ init, callback }) => {
    const childRef = useRef()
    const size = useWindow()
    const [origin, setOrigin] = useState(init.origin)
    const [destination, setDestination] = useState(init.destination)
    const [start, setStart] = useState(init.start)
    const [flow, setFlow] = useState(init.flow)
    const [split, setSplit] = useState(init.split)
    const [originValues, setOriginValues] = useState([])
    const [destinationValues, setDestinationValues] = useState([])
    const [originValue, setOriginValue] = useState([])
    const [destinationValue, setDestinationValue] = useState([])
    
    const [families, setFamilies] = useState([])
    const [grades, setGrades] = useState([])
    const [subGrades, setSubGrades] = useState([])
    const [family, setFamily] = useState([])
    const [grade, setGrade] = useState(init.grade)
    const [subGrade, setSubGrade] = useState([])
    const [regions, setRegions] = useState([])
    const [subRegions, setSubRegions] = useState([])
    const [countries, setCountries] = useState([])
    const [first, setFirst] = useState(true)
    

    const previous = usePrevious({ originValue, destinationValue, destination, family, regions, subRegions, countries });
    
    useEffect(() => {
        async function fetch() {
            const { regions, sub_regions, countries, families } = await list2('flows/conditions')
            setFamilies(families)
            setFamily(init.family)
            setRegions(regions)
            setSubRegions(sub_regions)
            setCountries(countries)
            setOriginValue(init.originValue)
            setDestinationValue(init.destinationValue)
            await sleep(500)
            setFirst(false)
        }   
        fetch()
    }, [])

    useEffect(() => {
        const find = families.find(({ id }) => id === family)
        if (find) {
            setGrades(find.grade)
            setGrade(find.grade.map(({ name }) => name))
        }
    }, [family])

    useEffect(() => {
        const finds = grades.filter(({ name }) => grade.includes(name))
        if (finds.length > 0) {
            const group = finds.flatMap(({ sub_grade }) => sub_grade)
            setSubGrades(group)
            setSubGrade(group.map(({ name }) => name))
        }
    }, [grade])

    useEffect(() => {
        const group = origin === 'region' ? regions : origin === 'sub_region' ? subRegions : countries
        const id = `origin_${family}`
        const orderGroup = group.filter((item) => item[id]).sort((a, b) => a[id] - b[id])
        setOriginValues(orderGroup)
        if (!first) setOriginValue(orderGroup.length > 0 ? [orderGroup[0].name] : [])
    }, [origin, family, regions, subRegions, countries])

    useEffect(() => {
        const group = destination === 'region' ? regions : destination === 'sub_region' ? subRegions : countries
        const id = `destination_${family}`
        const orderGroup = group.filter((item) => item[id]).sort((a, b) => a[id] - b[id])
        setDestinationValues(orderGroup)
        if (!first) setDestinationValue(orderGroup.length > 0 ? [orderGroup[0].name] : [])
    }, [destination, family, regions, subRegions, countries])

    useEffect(() => {
        if (previous) {
            const { originValue: ov, destinationValue: dv } = previous
            if (ov.toString() !== originValue.toString()) {
                const legalValues = destinationValue.filter((value) => !originValue.includes(value))
                setDestinationValue(legalValues)
            } else if (dv.toString() !== destinationValue.toString()) {
                const legalValues = originValue.filter((value) => !destinationValue.includes(value))
                setOriginValue(legalValues)
            }
        }
    }, [originValue, destinationValue])

    const confirm = () => {
        if (originValue.length === 0) {
            childRef.current.showError('Origin is empty')
        } else if (destinationValue.length === 0) {
            childRef.current.showError('Destination is empty')
        } else {
            callback({ split, flow, family, origin, destination, originValue, destinationValue, grade: grade.length === grades.length ? '' : grade, start, end: moment(start).add(11, 'month').endOf('month').format('YYYY-MM-DD') })
        }
    }
    const onSelectFamily = event => {
        setFamily(event.target.value)
    }
    const onSelectGrade = event => {
        const { target: { value } } = event
        if (value.includes('select_all')) {
            if (grade.length === grades.length) {
                setGrade([])
            } else {
                setGrade(grades.map(({ name }) => name))
            }
        } else {
            setGrade(value)
        }
    }
    const onSelectSubGrade = event => {
        const { target: { value } } = event
        if (value.includes('select_all')) {
            if (subGrade.length === subGrades.length) {
                setSubGrade([])
            } else {
                setSubGrade(subGrades.map(({ name }) => name))
            }
        } else {
            setSubGrade(value)
        }
    }
    const onSelectStart = event => {
        setStart(event.target.value)
    }
    const onSelectSplit = event => {
        setSplit(event.target.value)
    }
    const onSelectOrigin = event => {
        setOrigin(event.target.value)
    }
    const onSelectDestination = event => {
        setDestination(event.target.value)
    }
    const onSelectOriginValue = (event) => {
        const { value } = event.target
        if (value.includes('select_all')) {
            if (originValue.length === originValues.length) {
                setOriginValue([])
            } else {
                setOriginValue(originValues.map(({ name }) => name))
            }
        } else {
            setOriginValue(value)
        }
    }
    const onSelectDestinationValue = (event) => {
        const { value } = event.target
        if (value.includes('select_all')) {
            if (destinationValue.length === destinationValues.length) {
                setDestinationValue([])
            } else {
                setDestinationValue(destinationValues.map(({ name }) => name))
            }
        } else {
            setDestinationValue(value)
        }
    }
    const onSelectFlow = event => {
        setFlow(event.target.value)
    }
    return (
        <div className='row-start'>
            <Message ref={childRef} />
            <Grid container>
                <Grid item xs={4} md={2}>
                    <div className='row-left w-full my-2'>
                        <FormControl>
                            <div className='mb-4'><InputLabel>Start Month</InputLabel></div>
                            <Select value={start} onChange={onSelectStart}>
                                {starts.map((item) => (
                                    <MenuItem key={`flows_start_key_${item}`} value={item}>{moment(item).format('MMM YY')}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item xs={4} md={2}>
                    <div className='row-left w-full my-2'>
                        <FormControl>
                            <div className='mb-4'><InputLabel>Flow</InputLabel></div>
                            <Select value={flow} onChange={onSelectFlow}>
                                {flows.map(item => (
                                    <MenuItem key={`flows_flow_key_${item}`} value={item}>{pascal(item)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item xs={4} md={2}>
                    <div className='row-left w-full my-2'>
                        <FormControl>
                            <div className='mb-4'><InputLabel>Split By</InputLabel></div>
                            <Select value={split} onChange={onSelectSplit}>
                                {splits.map(item => (
                                    <MenuItem key={`flows_split_key_${item}`} value={item}>{pascal(item)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item xs={4} md={2}>
                    <div className='row-left w-full my-2'>
                        <FormControl>
                            <div className='mb-4'><InputLabel>Family</InputLabel></div>
                            <Select value={family} onChange={onSelectFamily}>
                                {families.map(({ id, name }) => (
                                    <MenuItem key={`flows_family_key_${id}`} value={id}>{name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item xs={4} md={2}>
                    <div className='row-left w-full my-2'>
                        <FormControl>
                            <div className='mb-4'><InputLabel>Grade</InputLabel></div>
                            <Select multiple value={grade}
                                onChange={onSelectGrade} renderValue={(selected) => {
                                if (grade.length === grades.length) {
                                    return 'All Items'
                                } else if (selected.length === 1) {
                                    return grades.find(({ name }) => name === selected[0]).name;
                                } else if (selected.length === 0) {
                                    return `No Item`
                                } else {
                                    return `${selected.length} Items`
                                }
                            }}>
                                {flow === 'import' && <MenuItem key={`flows_grade_select_all`} value={'select_all'}>
                                    <Checkbox checked={grade.length === grades.length} />
                                    <ListItemText primary={'All'} />
                                </MenuItem>}
                                {grades.map(({ id, name }) => (
                                    <MenuItem key={`flows_grade_level_key_${id}`} value={name}>
                                        <Checkbox checked={grade.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                {<Grid item xs={4} md={2}></Grid>}
                {/* <Grid item xs={4} md={2}>
                    <div className='row-left w-full my-2'>
                        <FormControl>
                            <div className='mb-4'><InputLabel>Sub Grade</InputLabel></div>
                            <Select multiple value={subGrade}
                                onChange={onSelectSubGrade} renderValue={(selected) => {
                                if (subGrade.length === subGrades.length) {
                                    return 'All Items'
                                } else if (selected.length === 1) {
                                    return subGrades.find(({ name }) => name === selected[0]).name;
                                } else if (selected.length === 0) {
                                    return `No Item`
                                } else {
                                    return `${selected.length} Items`
                                }
                            }}>
                                {flow === 'import' && <MenuItem key={`flows_sub_grade_select_all`} value={'select_all'}>
                                    <Checkbox checked={subGrade.length === subGrades.length} />
                                    <ListItemText primary={'All'} />
                                </MenuItem>}
                                {subGrades.map(({ id, name }) => (
                                    <MenuItem key={`flows_sub_grade_level_key_${id}`} value={name}>
                                        <Checkbox checked={subGrade.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Grid> */}
                <Grid item xs={4} md={2}>
                    <div className='row-left w-full my-2'>
                        <FormControl>
                            <div className='mb-4'><InputLabel>Origin Level</InputLabel></div>
                            <Select value={origin} onChange={onSelectOrigin}>
                                {levels.map(item => (
                                    <MenuItem key={`flows_origin_level_key_${item}`} value={item}>{pascal(item)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item xs={4} md={2}>
                    <div className='row-left w-full my-2'>
                        <FormControl>
                            <div className='mb-4'><InputLabel>Origin</InputLabel></div>
                            <Select multiple value={originValue}
                                onChange={onSelectOriginValue} renderValue={(selected) => {
                                if (originValue.length === originValues.length) {
                                    return 'All Items'
                                } else if (selected.length === 1) {
                                    return selected[0];
                                } else if (selected.length === 0) {
                                    return `No Item`
                                } else {
                                    return `${selected.length} Items`
                                }
                            }}>
                                <MenuItem key={`flows_origin_value_select_all`} value={'select_all'}>
                                    <Checkbox checked={originValue.length === originValues.length} />
                                    <ListItemText primary={'All'} />
                                </MenuItem>
                                {originValues.map(({ name }) => (
                                    <MenuItem key={`flows_origin_value_key_${name}`} value={name}>
                                        <Checkbox checked={originValue.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                {size.width < 960 && <Grid item xs={4}></Grid>}
                <Grid item xs={4} md={2}>
                    <div className='row-left w-full my-2'>
                        <FormControl>
                            <div className='mb-4'><InputLabel>Destination Level</InputLabel></div>
                            <Select value={destination} onChange={onSelectDestination}>
                                {levels.map(item => (
                                    <MenuItem key={`flows_destination_level_key_${item}`} value={item}>{pascal(item)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item xs={4} md={2}>
                    <div className='row-left w-full my-2'>
                        <FormControl>
                            <div className='mb-4'><InputLabel>Destination</InputLabel></div>
                            <Select multiple value={destinationValue} 
                                onChange={onSelectDestinationValue} renderValue={(selected) => {
                                if (destinationValue.length === destinationValues.length) {
                                    return 'All Items'
                                } else if (selected.length === 1) {
                                    return selected[0];
                                } else if (selected.length === 0) {
                                    return `No Item`
                                } else {
                                    return `${selected.length} Items`
                                }
                            }}>
                                <MenuItem key={`flows_destination_value_select_all`} value={'select_all'}>
                                    <Checkbox checked={destinationValue.length === destinationValues.length} />
                                    <ListItemText primary={'All'} />
                                </MenuItem>
                                {destinationValues.map(({ name }) => (
                                    <MenuItem key={`flows_destination_value_key_${name}`} value={name}>
                                        <Checkbox checked={destinationValue.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                {size.width >= 960 && <Grid item md={2}></Grid>}
                <Grid item xs={4} md={2}>
                    <div className='row-left w-full pt-6'>
                        <FormControl>
                            <Button variant='outlined' size='small' onClick={confirm} className='ml-2'>Confirm</Button >
                        </FormControl>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
