import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import useWindow from '../../hooks/useWindow'
import { singleChartWidth, chartHeight, post2 } from '../../services';
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const InventoryChart = ({ data }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        if (!data) return;
        const { title, subtitle, result } = data
        const options = {
            chart:  { 
                type: 'column',
                width: singleChartWidth(size.width),
                height: chartHeight(size.height)
            },
            exporting: {
                buttons: {
                    contextButton: {
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            },
            title: {
                text: title,
                useHtml: true,
                align: 'center'
            },
            subtitle: {
                text: subtitle,
                align: 'right'
            },
            yAxis: { title: { text: '' } },
            xAxis: {
                title: { text: '' },
                tickPositions: result[0].data.map(({ date }, i) => i % 4 === 0 ? i : null).filter((i) => i),
                categories: result[0].data.map(({ date }) => date).sort((a, b) => moment(a).isAfter(b) ? 1 : -1)
            },
            legend:{
                maxHeight: 100
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: result.map(({ name, data }) => ({ name, data: data.sort((a, b) => a.date - b.date).map(({ level }) => level )}))
        }
        setOptions(options)
    }, [data, size]);

    return (
    <div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}