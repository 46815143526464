import { useState, useEffect, useContext, useRef } from 'react';
import { TextField, Card, Grid, Button } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DispatchContext } from "../../../reducers";
import { ReportItem } from '.';
import { list2, defaultTimeFormatter } from '../../../services/Api';
import { Icon, Message } from '../../../components'

export const ComboBox = ({ options, callback, placeholder="Select Report", kind }) => {
     
    const handleChange = (event, value) => {
        callback(value)
    }
    const handleInputChange = (event, value) => {
        // callback(value)
    }
    return (
        <Autocomplete
            options={options}
            getOptionLabel={({ label }) => label}
            onChange={handleChange}
            onInputChange={handleInputChange}
            style={{ width: '100%', maxWidth: '500px', minWidth: '200px' }}
            renderOption={({ label, typeshort = 'Other', product = 'Other' }) => 
                <div className='row-between w-full'>
                    <label className='bold f-02'>{label}</label>
                    <label className='f-01 gray'>{kind === 'type' ? typeshort : product}</label>
                </div>
            }
            renderInput={(params) => <TextField {...params} label={placeholder} variant="outlined" />}
        />
    );
}

export const DirectoryList = ({ directories, callback }) => {
    const onSelect = event => {
        callback(event)
    }
    return (
        <div className=''>
            {directories.map((directory) => {
                return (
                    <div key={`key_directories_${directory.label}`} className='mb-3 row-between' onClick={() => onSelect(directory)}>
                        <div className=''>
                            <Icon icon='faSolidFolder' color='orange' /><label className='ml-1 dark_blue'>{directory.label}</label>
                        </div>
                        <div className='f-02'>
                            {defaultTimeFormatter(directory.lastupdate)}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export const Report = () => {
    const dispatch = useContext(DispatchContext)
    const childRef = useRef()
    const [types, setTypes] = useState([]);
    const [type, setType] = useState();
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState();
    const [allDirectories, setAllDirectories] = useState([]);
    const [directories, setDirectories] = useState([]);
    const [directory, setDirectory] = useState();
    const [files, setFiles] = useState([])
    const [file, setFile] = useState()
    const [kind, setKind] = useState('type')

    useEffect(() => {
        async function fetch() {
            dispatch({ type: 'spin on' })
            const data = await list2('report', 'list')
            dispatch({ type: 'spin off' })
            const types = [...new Set(data.map(({ type }) => type).filter((type) => type))]
            const products = [...new Set(data.map(({ product }) => product).filter((product) => product)), 'Other']
            setTypes(types)
            setProducts(products)
            setAllDirectories(data)
        }
        fetch()
    }, [])

    const onSelectKind = event => {
        setType(event)
        const directories = allDirectories.filter((directory) => event === 'Other' ? !directory[kind] : directory[kind] === event)
        setDirectories(directories)
        if (directories.length === 1) {
            onCallback(directories[0])
        }
    }

    const onCallback = event => {
        async function fetch() {
            if (!event) {
                return
            }
            dispatch({ type: 'spin on' })
            const files = await list2('report/list', event.name)
            dispatch({ type: 'spin off' })
            setDirectory(event)
            setFiles(files)
            setFile(null)
        }
        fetch()
    }

    const goBack = () => {
        if (file) {
            setFile(null)
        } else if (directory) {
            setDirectory(null)
            setFiles([])
            if (directories.length === 1) {
                setType(null)
                setDirectories([])
            }
        } else {
            setType(null)
            setDirectories([])
        }
    }

    const onSelectFile = file => {
        setFile(file)
    }

    const onSwitch = () => {
        setKind(kind === 'type' ? 'product' : 'type')
        childRef.current.showSuccess(`View by ${kind === 'type' ? 'Product' : 'Report Type'}`)
    }

    return (
        <div className='column-center w-full'>
            <Message ref={childRef} />
            <div className='mt-4 row-between w-95p'>
                <Grid container spacing={1} className='row-center px-3'> 
                    <Grid item xs={1}  className='row-left'> 
                        {!type && !product && <div><div className='' onClick={onSwitch}><Icon icon={kind === 'type' ? 'faArchive' : 'faDatabase'} color='gray' /></div ></div>}
                    </Grid>
                    <Grid item xs={10}  className='row-center'> 
                        <ComboBox options={allDirectories} callback={onCallback} kind={kind} />
                    </Grid>
                    <Grid item xs={1}  className='row-right'> 
                        {(type || product) && <div className='' onClick={goBack}><Icon icon='faReply' /></div >}
                    </Grid>
                </Grid>
            </div>
            {!type && !product && <div className='mt-6 w-full'>
                <Grid container spacing={2} className='row-center px-3'>
                    {(kind === 'type' ? types : products).map(item => (
                        <Grid item sm={4} md={3} lg={2} key={`country_item_${item}`} className='column-center' onClick={() => onSelectKind(item)}>                    
                            <Card className='column-center'>
                                <div className='w-100 h-40 row-center'>
                                    <Button component="label" size='large'>
                                        <Icon icon='faSolidFolder' color='orange' size='3x' />
                                    </Button>
                                </div>
                                <div className="bold f-01 mb-3">
                                    {item}
                                </div>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>}
            <div className='mt-6 w-95p row-between'>
                {type && !directory && <div><Icon icon='faFolderOpen' color='orange' /><label className='ml-1'>{type}</label></div>}
                {directory && !file && <div><Icon icon='faFolderOpen' color='orange' /><label className='ml-1'>{directory.label}</label></div>}
                {directory && file && <div><Icon icon='faFile' color='green' /><label className='ml-1'>{file.label}</label></div>}
                {type && !directory && directories.length > 1 && <label className='mr-2 f-02'>updated at</label>}
                {directory && !file && <label className='mr-2 f-02'>size</label>}
            </div>
            <div className='mt-3 w-90p'>
                {!directory && directories.length > 1 && <DirectoryList directories={directories} callback={onCallback} width={'120px'} height={'120px'} />}
            </div>
            <div className='w-90p'>
                {!file && files.map((file) => {
                    return (
                        <div key={`key_files_${directory.name}_${file.name}`} className=''>
                            <div className='row-between mb-3'>
                                <div className='dark_blue' onClick={() => onSelectFile(file)}>
                                    <Icon icon='faFile' color='green' /><label className='ml-1'>{file.label}</label>
                                </div>
                                <div className=''>
                                    {file.size}
                                </div>
                            </div>
                        </div>
                    )
                })}
                {directory && files.length === 0 && <label className='ml-3'>No File</label>}
            </div>
            {file && <ReportItem dirname={directory.name} filename={file.name} label={file.label} />}
        </div>
    )
}
