import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useWindow from '../../hooks/useWindow'
import { singleChartWidth, chartHeight, post2 } from '../../services';
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const RefineryChart = ({ data, title, subtitle, yaxis }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        if (!data) return
        const { stackColumns, lines, categories } = data
        const options = {
            chart: { width: singleChartWidth(size.width), height: chartHeight(size.height) },
            exporting: {
                buttons: {
                    contextButton: {
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            },
            title: { text: title, align: 'center' },
            subtitle: { text: subtitle, align: 'right' },
            xAxis: { title: { text: '' }, categories: categories.map(({ x }) => x) },
            legend:{ maxHeight: 100 },
            tooltip: {
                formatter: function () {
                    const found = categories.find(({ x }) => x === this.x)
                    const res = this.points.reduce((pre, cur, idx) => {
                        const year = found.same_year ? +cur.series.userOptions.name : +cur.series.userOptions.name + 1
                        const name = cur.series.userOptions.type === 'column' ? cur.series.userOptions.name : `${found.month} ${year.toString().slice(-2)}`
                        const sep = idx > 0 && cur.series.userOptions.type !== this.points[idx - 1].series.userOptions.type ? '<hr /><br />' : ''
                        return `${pre}<br />${sep}<div><b>${name}:</b>&nbsp;${cur.y}</div>`
                    }, `<b>${this.x}</b>` );
                    return res
                },
                shared: true
            },
            yAxis: {
                title: { text: '' },
                stackLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'allow',
                    style: { fontWeight: 'bold', color: 'gray' }
                }
            },
            plotOptions: { column: { stacking: 'normal', dataLabels: { enabled: false } } },
            series: [
                ...(stackColumns ? stackColumns.data.map(({ name, data }) => ({ type: 'column', name, data })) : []),
                ...lines.map(({ name, data }) => ({ type: 'spline', name, data }))
            ]
        }
        setOptions(options)
    }, [data, title, subtitle, yaxis, size]);

    return (<div><HighchartsReact highcharts={Highcharts} options={options} /></div>)
}