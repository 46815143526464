import { useState, useEffect, useContext, useRef } from 'react';
import { Button, Select, MenuItem, InputLabel, Checkbox, ListItemText, Input, FormControl, Divider } from '@material-ui/core';
import moment from 'moment'
import { list2, pascal } from '../../../services'
import { DispatchContext } from "../../../reducers";
import { Message } from '../../../components'
import { usePrevious } from '../../../hooks'

export const StorageFilter = ({ init, callback }) => {
    const dispatch = useContext(DispatchContext)
    const childRef = useRef()
    const types = ['country', 'installation', 'tank']
    const [countries, setCountries] = useState([])
    const [installations, setInstallations] = useState([])
    const [tanks, setTanks] = useState([])
    const [starts, setStarts] = useState([])
    const [ends, setEnds] = useState([])
    const [type, setType] = useState(init.type)
    const [country, setCountry] = useState(init.value)
    const [installation, setInstallation] = useState([])
    const [tank, setTank] = useState([])
    const [start, setStart] = useState(init.start)
    const [end, setEnd] = useState(moment(init.end).startOf('month').format('YYYY-MM-DD'))
    const previous = usePrevious({ type, country, installation });

    const getMonthRange = (start, end) => {
        const range = []
        while (start <= end) {
            range.push(start)
            start = moment(start).add(1, 'month').format('YYYY-MM-DD')
        }
        return range
    }

    useEffect(() => {
        if (!previous) {
            initFetch()
        } else {
            fetch()
        }

        async function initFetch () {
            dispatch({ type: 'spin on' })
            const { values, start, end } = await list2('onshoreInventory', 'condition', { type, country, installation })
            dispatch({ type: 'spin off' })
            if (type === 'country') {
                setCountries(values)
            } else if (type === 'installation') {
                setInstallations(values)
            } else {
                setTanks(values)
            }
            const range = getMonthRange(start, end)
            setStarts(range)
            setEnds(range)
        }
        async function fetch () {
            const { type: oldType, country: oldCountry, installation: oldInstallation } = previous
            const typeChanged = type !== oldType
            const countryChanged = country.toString() !== oldCountry.toString()
            const installationChanged = installation.toString() !== oldInstallation.toString()
            if (typeChanged || (!typeChanged && ((type === 'installation' && countryChanged) || (type === 'tank' && (countryChanged || installationChanged))))) {
                dispatch({ type: 'spin on' })
                const { values } = await list2('onshoreInventory', 'condition', { type, country, installation })
                dispatch({ type: 'spin off' })
                if (type === 'country') {
                    setCountries(values)
                } else if (type === 'installation') {
                    setInstallations(values)
                } else {
                    setTanks(values)
                }
            }
            if (typeChanged) {
                if (type === 'country') {
                    setInstallation([])
                } else if (type === 'installation') {
                    setTank([])
                }
            } else {
                if (type === 'installation') {
                    if (countryChanged) {
                        setInstallation([])
                    }
                } else if (type === 'tank') {
                    if (countryChanged || installationChanged) {
                        setTank([])
                    }
                }
            }
        }
    }, [type, country, installation])

    const onSelectType = (event) => {
        setType(event.target.value)
    }

    const onSelectCountry = (event) => {
        const { value } = event.target
        if (value.includes('select_all')) {
            if (value.length === countries.length + 1) {
                setCountry([])
            } else {
                setCountry(countries.map(({ id }) => id))
            }
        } else {
            setCountry(value)
        }
    }

    const onSelectInstallation = (event) => {
        const { value } = event.target
        if (value.includes('select_all')) {
            if (value.length === installations.length + 1) {
                setInstallation([])
            } else {
                setInstallation(installations.map(({ id }) => id))
            }
        } else {
            setInstallation(value)
        }
    }

    const onSelectTank = (event) => {
        const { value } = event.target
        if (value.includes('select_all')) {
            if (value.length === tanks.length + 1) {
                setTank([])
            } else {
                setTank(tanks.map(({ id }) => id))
            }
        } else {
            setTank(value)
        }
    }

    const onSelectStart = (event) => {
        setStart(event.target.value)
    }

    const onSelectEnd = (event) => {
        setEnd(event.target.value)
    }

    const confirm = () => {
        const monthEnd = moment(end).endOf('month').format('YYYY-MM-DD')
        if (!moment(start).isBefore(moment(monthEnd))) {
            childRef.current.showError('illegal month range')
        } else if (type === 'country' && country.length === 0) {
            childRef.current.showError(`country is empty`)
        } else if (type === 'installation' && installation.length === 0) {
            childRef.current.showError(`installation is empty`)
        } else if (type === 'tank' && tank.length === 0) {
            childRef.current.showError(`tank is empty`)
        } else {
            const values = type === 'country' ? countries : type === 'installation' ? installations : tanks
            const value = type === 'country' ? country : type === 'installation' ? installation : tank
            const valueIds = values.map(({ id }) => id)
            const order = value.map((val) => valueIds.indexOf(val))
            const name = values[0] ? values[0].name : '' //order.map((idx) => values[idx].name)
            callback({ type, value, start, end: monthEnd, order, name })
        }
    }

    return (
        <div className="row-start">
            <Message ref={childRef} />
            <div className='row-between my-2'>
                <FormControl>
                    <InputLabel>Start</InputLabel>
                    <Select value={start} onChange={onSelectStart} >
                        {starts.map((month) => (
                            <MenuItem key={`key_storage_start_${month}`} value={month}>
                                {moment(month).format('MMM YYYY')}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>End</InputLabel>
                    <Select value={end} onChange={onSelectEnd} >
                        {ends.map((month) => (
                            <MenuItem key={`key_storage_end_${month}`} value={month}>
                                {moment(month).format('MMM YYYY')}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div> 
            <div className='row-between my-2'>
                <FormControl>
                    <InputLabel>Country</InputLabel>
                    <Select multiple value={country} onChange={onSelectCountry} input={<Input /> } displayEmpty={true}
                        renderValue={(selected) => {
                            if (country.length === countries.length) {
                                return 'All'
                            } else if (selected.includes('Others')) {
                                return 'Multiple Selected';
                            } else if (selected.length === 1) {
                                return countries.find(({ id }) => id === selected[0])?.name;
                            } else if (selected.length === 0) {
                                return ''
                            } else {
                                return `${selected.length} Selected`
                            }
                        }} >
                        <MenuItem key={`key_storage_country_select_all`} value={'select_all'}>
                            <Checkbox checked={country.length === countries.length} />
                            <ListItemText primary={'All'} />
                        </MenuItem>
                        <Divider variant="fullWidth" />
                        {countries.map(({ name, id }) => (
                            <MenuItem key={`key_storage_country_${id}`} value={id}>
                                <Checkbox checked={country.indexOf(id) > -1} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>Scale</InputLabel>
                    <Select value={type} onChange={onSelectType} >
                        {types.map((t) => (
                            <MenuItem key={`key_storage_type_${t}`} value={t}>
                                By {pascal(t)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div> 
            {['installation', 'tank'].includes(type) && <div className='row-left my-2'>
                <FormControl>
                    <InputLabel>installation</InputLabel>
                    <Select multiple value={installation} onChange={onSelectInstallation} input={<Input /> } displayEmpty={true}
                        renderValue={(selected) => {
                            if (installation.length === installations.length) {
                                return 'All'
                            } else if (selected.includes('Others')) {
                                return 'Multiple Selected';
                            } else if (selected.length === 1) {
                                return installations.find(({ id }) => id === selected[0])?.name;
                            } else if (selected.length === 0) {
                                return ''
                            } else {
                                return `${selected.length} Selected`
                            }
                        }} >
                        <MenuItem key={`key_storage_installation_select_all`} value={'select_all'}>
                            <Checkbox checked={installation.length === installations.length} />
                            <ListItemText primary={'All'} />
                        </MenuItem>
                        <Divider variant="fullWidth" />
                        {installations.map(({ name, id }) => (
                            <MenuItem key={`key_storage_installation_${id}`} value={id}>
                                <Checkbox checked={installation.indexOf(id) > -1} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>}
            {type === 'tank' && <div className='row-left my-2'>
                <FormControl>
                    <InputLabel>{pascal(type)}</InputLabel>
                    <Select multiple value={tank} onChange={onSelectTank} input={<Input /> } displayEmpty={true}
                        renderValue={(selected) => {
                            if (tank.length === tanks.length) {
                                return 'All'
                            } else if (selected.includes('Others')) {
                                return 'Multiple Selected';
                            } else if (selected.length === 1) {
                                return tanks.find(({ id }) => id === selected[0])?.name;
                            } else if (selected.length === 0) {
                                return ''
                            } else {
                                return `${selected.length} Selected`
                            }
                        }} >
                        <MenuItem key={`key_storage_tank_select_all`} value={'select_all'}>
                            <Checkbox checked={tank.length === tanks.length} />
                            <ListItemText primary={'All'} />
                        </MenuItem>
                        <Divider variant="fullWidth" />
                        {tanks.map(({ name, id }) => (
                            <MenuItem key={`key_storage_tank_${id}`} value={id}>
                                <Checkbox checked={tank.indexOf(id) > -1} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>}
            <div className='row-center mt-6'>
                <Button variant='outlined' onClick={confirm} className='ml-2'>Confirm</Button >
            </div>
        </div>
    )
}
