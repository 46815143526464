import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useWindow from '../../hooks/useWindow'
import moment from 'moment';
import { singleChartWidth, chartHeight, post2 } from '../../services';
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const StepChart = ({ data: stepData }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        const { data, title, subtitle } = stepData
        const options = {
            chart: {
                width: singleChartWidth(size.width),
                height: chartHeight(size.height),
                step: true
            },
            exporting: {
                buttons: {
                    contextButton: {
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            },
            title: {
                text: title,
            }, 
            subtitle: {
                text: subtitle,
            },
            xAxis: {
                type: "datetime",
                labels: {
                    formatter: params => Highcharts.dateFormat('%b %e', params.value)   
                }
            },
            yAxis: {
                title: {
                    text: ''
                }
            },
            tooltip: {
                formatter: function() {
                    return `<b>${moment(this.point.x).format('MMM DD')}</b><br /><b>${this.series.userOptions.name} ${this.point.y} ${this.series.userOptions.unit}</b>`
                }
            },
            series: data
        }
        setOptions(options)
    }, [stepData, size]);

    return (<div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}