import React, { useState, useEffect, useContext } from 'react';
import { list2 } from '../../services/Api'
import { DispatchContext } from "../../reducers";
import { UserGrid } from '../../components';
import { UserInput } from '.'

export const UserList = () => {
    const dispatch = useContext(DispatchContext)
    const [users, setUsers] = useState()

    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const data = await list2('user/all')
            dispatch({ type: 'spin off' })
            setUsers(data)
        }
        fetch()
    }, [])

    return (
        <div>
            {/* <UserInput /> */}
            <UserGrid data={users} />
        </div>
    )
}