import { useEffect, useState, useContext } from 'react';
import { Grid, TextField, IconButton, Card, CardContent, Tabs, Tab, Select, MenuItem } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import { DispatchContext } from "../../reducers";
import { list2, sleep } from "../../services";
import { ExportPpt, Icon } from '../../components'
import { keys as companyKeys, CompanyDetailSector } from './CompanyDetail';

export const options = [
    { value: 'companyProfileSnapshot', label: 'Overview' },
    { value: 'organizationalStructure', label: 'Structure' },
    { value: 'globalAssetsSnapshot', label: 'Snapshot' },
    { value: 'boardMembers', label: 'Directors' },
    { value: 'financialPerformance', label: 'Financial' },
    { value: 'companyAssets', label: 'Assets' },
    { value: 'energyTransition', label: 'Energy Transision' },
    { value: 'tweet', label: 'Tweet' },
    // { value: 'tradeVolume', label: 'Trade Volume' },
]

export const ComboBox = ({ options, callback, placeholder="Select Company" }) => {
     
    const handleChange = (event, value) => {
        callback(value)
    }
    const handleInputChange = (event, value) => {
        // callback(value)
    }
    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.name}
            onChange={handleChange}
            onInputChange={handleInputChange}
            style={{ width: '80%', maxWidth: '500px', minWidth: '280px' }}
            renderOption={(option) => 
                <div className='row-center'>
                    <img loading="lazy" width="30" src={option.image} alt="" />
                    <label className='ml-3'>{option.name}</label>
                </div>
            }
            renderInput={(params) => <TextField {...params} label={placeholder} variant="outlined" />}
        />
    );
}

export const SelectBox = ({ options, callback, init, placeholder }) => {
    const [value, setValue] = useState()
    useEffect(() => {
        setValue(init)
    }, [init])
    const handleChange = (event) => {
        const { target: { value } } = event
        const find = options.find(({ id }) => id === value)
        callback(find)
        setValue(value)
    }
    return (
        <Select value={value || 0} onChange={handleChange} style={{ width: '80%', maxWidth: '500px', minWidth: '280px', height: '77px' }} variant='outlined'>
            <MenuItem value={0}>{placeholder}</MenuItem>
            {options.map((item) => {
                const { id, name, src } = item
                return (
                    <MenuItem key={`key_selectbox_${id}`} value={id}>
                        <div className='row-left'>
                            <img loading="lazy" width="30" src={src} alt="" />
                            <label className='ml-3'>{name}</label>
                        </div>
                    </MenuItem>
                )
            })}
        </Select>
    );
}

export const ProfileList = ({ items, callback, width='120px', height='120px' }) => {
    const onSelect = event => {
        callback(event)
    }
    return (
        <Grid container className='row-center px-3'>
            {items.map(item => (
                <Grid item sm={4} md={3} lg={2}  key={`company_item_${item.name}`} className='column-center' onClick={() => onSelect(item)}>                    
                    <Card style={{ width, height }} className='row-center m-4' >
                        <CardContent style={{ textAlign: "center" }}>
                            <img src={item.src} alt={item.name} width={'100%'} height={'100%'} />
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}

export const CompanyProfile = () => {
    const dispatch = useContext(DispatchContext)
    const [companies, setCompanies] = useState([])
    const [company, setCompany] = useState()
    const [scrollKeys, setScrollKeys] = useState([])
    const [scrollKey, setScrollKey] = useState('overview')

    useEffect(() => {
        async function fetch() {
            dispatch({ type: 'spin on' })
            const data = await list2('companyProfile')
            dispatch({ type: 'spin off' })
            setCompanies(data)
        }
        fetch()
    }, [])

    const onCallback = event => {
        async function fetch() {
            if (!event) return
            dispatch({ type: 'spin on' })
            const data = await list2('companyProfile', event.id, { identifier: event.identifier, name: event.name })
            dispatch({ type: 'spin off' })
            setCompany(data)
            const scrollOptions = []
            for (const option of options) {
                const exist = scrollOptions.find(({ label }) => label === option.label)
                if (data[option.value] && !exist) {
                    scrollOptions.push(option)
                }
            }
            setScrollKeys(scrollOptions)
            onTab(null, 'companyProfileSnapshot')
        }
        fetch()
    }

    const goBack = () => {
        setCompany(null)
    }

    const onTab = async (event, key) => {
        setScrollKey(key)
        await sleep(0)
        const el = document.getElementById(key)
        el.scrollIntoView()
    }
    const CustomTab = withStyles({
        root: {
            backgroundColor: '#00632e',
            color: 'white',
        },
        selected: {
            backgroundColor: 'white',
        },
    })(Tab);

    return (
        <div className='column-center'>
            <div className='onTop bg-white w-full pt-2'>
                <div className='row-around w-full'>
                    {company && <IconButton className='mr-2' onClick={goBack}><Icon icon='faArrowLeft' size='1x' /></IconButton >}
                    <SelectBox options={companies} callback={onCallback} init={company?.id} placeholder='Select Company' />
                    {company && <div className='mx-2'><ExportPpt type={'company_profile'} filename={`${company.name}`} /></div>}
                </div>
                {company && <div className='row-between w-full mt-1'>
                    <Tabs value={scrollKey} onChange={onTab} variant="scrollable" >
                        {scrollKeys.map(({ label, value }) => {
                            return <CustomTab key={`key_company_profile_index_${value}`} label={<div className='row-center f-01'>{label}</div>} value={value} />
                        })}
                    </Tabs>
                </div>}
            </div>
            <div className=''>
                {!company && <ProfileList items={companies} callback={onCallback} />}
            </div>
            {company && <div className='w-full'>
                {Object.entries(company).filter(([key, content]) => companyKeys.includes(key) && content).map(([key, content]) => {
                    return scrollKey === key && <CompanyDetailSector key={`key_company_profile_sector_${key}`} id={key} content={content} />
                })}
            </div>}
        </div>
    )
}
