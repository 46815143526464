import { useState, useReducer } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import Cookies from 'js-cookie'
import { Confirm, Dashboard, News, Price, Snapshot, Model, Profile, Qrcode, Subscription, Upload, User } from './tabs';
import { Layout, Login, Loading } from './layout';
import { GlobalMessage } from './components'
import { StateContext, DispatchContext, initialState, reducer } from './reducers'
import './styles/index.scss';

const App = () => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const [authed] = useState(Cookies.get('token') ? true : false)

    if (!authed) return (
        <HttpsRedirect>
            <StateContext.Provider value={state} >
                <DispatchContext.Provider value={dispatch} >
                    
                    <Router>
                        <Loading spin={state.spin}/>
                        <Switch>
                            <Route exact path="/">
                                <Login />
                            </Route>
                            <Route exact path="/:path">
                                <Login />
                            </Route>
                            <Route path="/confirm/:code">
                                <Confirm />
                            </Route>
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </DispatchContext.Provider>
            </StateContext.Provider>
        </HttpsRedirect>
    )

    if(authed && ['/qr-code', '/podcasts', '/podcasts-upload'].includes(window?.location.pathname))
        return (
          <HttpsRedirect>
             <StateContext.Provider value={state} >
                <DispatchContext.Provider value={dispatch} >
                    <Router>
                    <GlobalMessage newMessage={state.message} newSeverity={state.severity} newDuration={state.duration} />
                        <Switch>
                            <Route path="/qr-code">
                                <Qrcode />
                            </Route>
                        </Switch>
                    </Router>
                </DispatchContext.Provider>
            </StateContext.Provider>
          </HttpsRedirect>
        )
    
    return (
        <HttpsRedirect>
            <StateContext.Provider value={state} >
                <DispatchContext.Provider value={dispatch} >
                    <Router>
                            <Layout>
                                <Loading spin={state.spin}/>
                                <GlobalMessage newMessage={state.message} newSeverity={state.severity} newDuration={state.duration} />
                                <Switch>
                                    <Route exact path="/">
                                        <Dashboard />
                                    </Route>
                                    <Route path="/model">
                                        <Model />
                                    </Route>
                                    <Route path="/news">
                                        <News />
                                    </Route>
                                    <Route path="/price">
                                        <Price />
                                    </Route>
                                    <Route path="/profile">
                                        <Profile />
                                    </Route>
                                    <Route path="/snapshot">
                                        <Snapshot />
                                    </Route>
                                    <Route path="/subscription">
                                        <Subscription />
                                    </Route>
                                    <Route path="/upload">
                                        <Upload />
                                    </Route>
                                    <Route path="/user">
                                        <User />
                                    </Route>
                                    <Route path="/confirm">
                                        <Confirm />
                                    </Route>
                                    <Redirect to="/" />
                                    </Switch>
                            </Layout>
                    </Router>
                </DispatchContext.Provider>
            </StateContext.Provider>
        </HttpsRedirect>
    )
};

export default App;
