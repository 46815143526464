import { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import { numberCommaFormatter } from '../../services/Api';

export const HeatGrid = ({ data, type, callback }) => {
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState([])
    
    const [columnDefs, setColumnDefs] = useState([])

    const onGridReady = (params) => {
        setGridColumnApi(params.columnApi);
        autoSizeAll()
        window.onresizable = () => {
            autoSizeAll()
        }
    };

    const autoSizeAll = (skipHeader) => {
        if (gridColumnApi) {
            const allColumnIds = [];
            gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
        }
    };

    useEffect(() => {
        if (data.result) {
            const row = data.result.map(res => ({ name: res.name, origin: res.origin, destination: res.destination, total: res.total, currentValue: res.currentValue, ...res.data }))
            .sort((a, b) => b.total - a.total)
            setRowData(row)
        }
    }, [data])
    
    useEffect(() => {
        if (data.dates) {
            const current = moment(data.result[0].current).format('MMMYY')
            const cellStyle = param => {
                const delta = param.value/param.data.total*12 - 1
                if (delta > 3) {
                    return { backgroundColor: '#9cc400', textAlign: 'right' }
                } if (delta > 2) {
                    return { backgroundColor: '#c5e1a5', textAlign: 'right' }
                } else if (delta > 1) {
                    return { backgroundColor: '#D9E8CB', textAlign: 'right' }
                } else if (delta > 0) {
                    return { backgroundColor: '#EDF4E7', textAlign: 'right' }
                } else if (delta > -1) {
                    return { backgroundColor: '#f5c1b2', textAlign: 'right' }
                } else if (delta > -2) {
                    return { backgroundColor: '#e99771', textAlign: 'right' }
                } else {
                    return { backgroundColor: '#e5633f', textAlign: 'right' }
                }
            } 
            const conf = [
                ...data.dates.slice(-2).map(date => ({ field: date, headerName: moment(date).format('MMMYY'),  cellStyle, valueFormatter: param => param.value ? numberCommaFormatter(Math.round(param.value)) : 0 } )),
                { field: 'currentValue', headerName: current, cellStyle, valueFormatter: param => param.value ? numberCommaFormatter(Math.round(param.value)) : 0 },
                { field: 'currentValue', headerName: '12MA', valueFormatter: param => numberCommaFormatter(Math.round(param.data.total/12)) },
                { field: 'currentValue', headerName: '% Δ', valueFormatter: param => param.data.total === 0 ? 'N/A' : `${Math.round(param.value/param.data.total*1200 - 100)}%` },        
            ]
            if (data && data.result && data.result[0].name) {
                const newConf = [
                    { 
                        field: 'name', 
                        headerComponentParams: {
                            template: `
                                <div style="text-align:center;width:100%">
                                    ${type === 'import' ? 'Destination' : 'Origin'}
                                    <br />
                                    <small>(velocity chart)</small>
                                </div>
                            `
                        },
                        flex: 2, 
                        minWidth: 100, 
                        cellStyle: { textAlign: 'left', textDecoration: 'underline', color: 'blue' }, 
                        onCellClicked: param => onClickCallback(param) 
                    }, 
                    ...conf
                ]
                setColumnDefs(newConf)
            } else {
                const newConf = [
                    { 
                        field: 'origin', 
                        headerComponentParams: {
                            template: `
                                <div style="text-align:center;width:100%">
                                    Origin
                                    <br />
                                    <small>(velocity chart)</small>
                                </div>
                            `
                        },
                        flex: 2, 
                        minWidth: 100, 
                        cellStyle: { textAlign: 'left', textDecoration: 'underline', color: 'blue' }, 
                        onCellClicked: param => onClickCallback(param) 
                    }, 
                    { 
                        field: 'destination', 
                        headerComponentParams: {
                            template: `
                                <div style="text-align:center;width:100%">
                                    Destination
                                    <br />
                                    <small>(velocity chart)</small>
                                </div>
                            `
                        },
                        flex: 2, 
                        minWidth: 100, 
                        cellStyle: { textAlign: 'left', textDecoration: 'underline', color: 'blue' } , 
                        onCellClicked: param => onClickCallback(param) 
                    }, 
                    ...conf
                ]
                setColumnDefs(newConf)
            }
        }
    }, [data, type])

    const onClickCallback = (param) => {
        callback(param)
    }
    
    return (
        <div className="ag-theme-alpine ag-packed" style={{ height: 400, width: '99%', minWidth: 300 }}>
            <AgGridReact rowData={rowData} columnDefs={columnDefs} onGridReady={onGridReady}
                defaultColDef={{
                    resizable: true,
                    flex: 1,
                    minWidth: 50,
                    suppressMovable: true,
                    cellStyle: { textAlign: 'right' }
                }} 
            />
        </div>
    );
};