import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useWindow from '../../hooks/useWindow'
import { singleChartWidth, chartHeight, post2 } from '../../services';
import moment from 'moment';
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);


export const LineChart = ({ data: dataObj }) => {
    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        const { currency_code, name, country, data } = dataObj
        const subtitle = ['GDP', 'BOT'].includes(name) ? `Currency: ${currency_code}` : ''
        const options = {
            chart: {
                type: 'spline',
                width: singleChartWidth(size.width),
                height: chartHeight(size.height, 200)
            },
            exporting: {
                buttons: {
                    contextButton: {
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {
                                text: 'Send Email',
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                }
                            }
                        ]
                    }
                }
            },
            title: { text: '' },
            subtitle: { text: subtitle, align: 'right' },
            xAxis: {
                categories: data.map(d => moment(d.date).format('MMM YY')),
                tickInterval: Math.ceil(data.length / 10)
            },
            yAxis: { title: { text: '' } },
            series: [
                {
                    name: `${country}'s ${name}`,
                    data: data.map(d => d.value)
                }
            ]
        }
        setOptions(options)
    }, [dataObj, size])

    return <div className='row-center'>
        <HighchartsReact highcharts={Highcharts} options={options} />
    </div>

}