import { Fragment } from 'react';
import { Footer, Header } from '.';
import { Intraday } from '../components'

export const Layout = ({ children }) => {
    
    return (
        <Fragment>
            <main>
                <div className='main'>
                    <Header />
                    <div className='content'>{children}</div>
                    <Intraday />
                    <Footer />
                </div>
            </main>
        </Fragment>
    );
}