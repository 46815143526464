import { useState, useEffect } from 'react'
import useWindow from '../../hooks/useWindow'
import { chartHeight, singleChartWidth, post2 } from '../../services';
import Highcharts from "highcharts/highstock";
import HighchartsReact from 'highcharts-react-official'
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'

require('highcharts/indicators/indicators')(Highcharts)
require('highcharts/indicators/ema')(Highcharts)
require('highcharts/indicators/bollinger-bands')(Highcharts)
require('highcharts/indicators/macd')(Highcharts)
require('highcharts/indicators/rsi')(Highcharts)
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const StockChart = ({ data, conditions }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => { 
        if (data && data.length > 0) {
            const options = {
                chart: {
                    width: singleChartWidth(size.width),
                    height: chartHeight(size.height),
                    spacingLeft: 0,
                    spacingRight: 0
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            align: 'left',
                            menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                                {
                                    text: 'Send Email',
                                    onclick: function() {
                                        const csv = this.getCSV()
                                        const html = this.getSVG()
                                        const title = this.getFilename()
                                        post2('user/export_file', { csv, html, title })
                                    }
                                }
                            ]
                        }
                    }
                },
                title: {
                    text: conditions.instrument.name
                },
                yAxis: [
                    {
                        top: '0%',
                        height: '60%',
                        title: { text: '' },
                        opposite: false,
                    },
                    {
                        top: '60%',
                        height: '20%',
                        title: { text: 'Volume', margin: 0 },
                        labels: { enabled: false },
                        opposite: true,
                    },
                    {
                        top: '80%',
                        height: '20%',
                        title: { text: conditions.oscillator.title, margin: 0 },
                        labels: { enabled: false },
                        opposite: true,
                    }
                ],
                series: [
                    {
                        name: 'Price',
                        id: 'price',
                        type: 'candlestick',
                        data: data.map(dat => ({ open: dat.open, high: dat.high, low: dat.low, close: dat.close, x: new Date(dat.date).valueOf(), color: dat.open < dat.close ? 'green' : 'red' }))
                    },
                    {
                        name: 'Volume',
                        type: 'column',
                        id: 'volume',
                        yAxis: 1,
                        data: data.map(dat => ({ y: dat.volume, x: new Date(dat.date).valueOf() }))
                    },
                    {
                        type: conditions.overlay,
                        id: 'overlay',
                        linkedTo: 'price',
                        yAxis: 0
                    }, 
                    {
                        name: conditions.oscillator.label,
                        id: 'oscillator',
                        type: conditions.oscillator.name,
                        yAxis: 2,
                        linkedTo: 'price'
                    }
                ]
            }
            setOptions(options)
        }
    }, [data, size, conditions]);


    return (
        <div className='row-center mt-2'>
            {<HighchartsReact
                highcharts={Highcharts}
                constructorType={"stockChart"}
                options={options}
            />}
        </div>
    )
}