import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import useWindow from '../../hooks/useWindow'
import { singleChartWidth, chartHeight, post2 } from '../../services';
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const FlowsChart = ({ data }) => {

    const [options, setOptions] = useState({})
    const size = useWindow()

    useEffect(() => {
        const { columns, lines, year, title, subtitle, categories } = data
        const currentMonth = moment().year() > year ? 12 : moment().month()
        const options = {
            chart:  { 
                width: singleChartWidth(size.width),
                height: chartHeight(size.height, 200)
            },
            exporting: {
                buttons: {
                    contextButton: {
                        align: 'left',
                        menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",
                            {   
                                text: 'Send Email', 
                                onclick: function() {
                                    const csv = this.getCSV()
                                    const html = this.getSVG()
                                    const title = this.getFilename()
                                    post2('user/export_file', { csv, html, title })
                                } 
                            }
                        ]
                    }
                }
            },
            title: {
                text: title,
                useHtml: true,
                align: 'center'
            },
            subtitle: {
                text: subtitle,
                align: 'right'
            },
            xAxis: {
                title: { text: '' },
                categories: categories.map(({ x }) => x)
            },
            legend:{
                maxHeight: 150
            },
            yAxis: {
                title: {
                    text: ''
                },
                stackLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'allow',
                    style: {
                        fontWeight: 'bold',
                        color: 'gray'
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            series: [
                ...(lines ? [
                    {
                        name: 'Total',
                        type: 'spline',
                        data: lines.sum.slice(0, currentMonth + 1),
                    },
                    {
                        name: 'Foward Est.',
                        type: 'spline',
                        data: lines.sum.slice(currentMonth).map((val, idx) => ({ y: val, x: idx + currentMonth })),
                        dashStyle: "LongDash"
                    },
                    {
                        name: '5Y Median',
                        type: 'spline',
                        data: lines.median,
                    },
                    {
                        name: '5Y',
                        type: 'areasplinerange',
                        fillOpacity: 0.3,
                        marker: { enabled: false },
                        data: lines.range.map(({ y }) => y),
                    },
                ] : []),
                ...columns.map((dat, i) => ({
                    type: 'column',
                    name: dat.name,
                    data: dat.data,
                }))
            ]
        }
        setOptions(options)
    }, [data, size]);

    return (<div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}