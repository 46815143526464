import { useState, useEffect, useContext, useRef } from 'react';
import { Button, Dialog, Modal, TextField, Backdrop, Fade } from '@material-ui/core';
import { Icon, MenuComponent, Message } from '../components'
import { useHistory } from 'react-router'
import { post2, logout, change_password } from '../services/Api'
import { DispatchContext } from "../reducers";
import useWindow from '../hooks/useWindow';
import { browser } from '../services/Browser'
import Cookies from 'js-cookie';

export const Header = () => {

    const messageRef = useRef()
    const dispatch = useContext(DispatchContext)
    const size = useWindow()
    const history = useHistory()
    const [title, setTitle] = useState('Aramco Trading Analytics')
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [fullscreen, setFullscreen] = useState(false)
    const [openChange, setOpenChange] = useState(false)
    const [OldPassword, setOldPassword] = useState()
    const [password, setPassword] = useState()
    const [passwordRepeat, setPasswordRepeat] = useState()

    const [topOptions, setTopOptions] = useState([])
    const defaultBottomOptions = [
        // { label:<><Icon icon='faQrcode' /><label className='ml-2'>QR Code</label></>, name: 'qr-code' },
        { label: <label className=''><Icon icon='faCog' /><label className='ml-2'>Change Password</label></label>, name: 'change_password' },
        { label: <label className='red'><Icon icon='faSignOutAlt' /><label className='ml-2'>Logout</label></label>, name: 'logout' }
    ]
    const [bottomOptions, setBottomOptions] = useState(defaultBottomOptions)

    const options = [
        { label: <><Icon icon='faNewspaper' /><label className='ml-2'>News</label></>, name: 'news' },
        { label: <><Icon icon='faChartLine' /><label className='ml-2'>Price</label></>, name: 'price' },
        { label: <><Icon icon='faEye' /><label className='ml-2'>Snapshot</label></>, name: 'snapshot' },
        { label: <><Icon icon='faChartBar' /><label className='ml-2'>Model</label></>, name: 'model' },
        { label: <><Icon icon='faFolder' /><label className='ml-2'>Profile</label></>, name: 'profile' },
    ]
    useEffect(() => {
        const defaults = [
            { label:<><Icon icon='faHome' /><label className='ml-2'>Home</label></>, name: '' },
            { label:<><Icon icon='faBookmark' /><label className='ml-2'>Subscription</label></>, name: 'subscription' },
        ]
        const upload = Cookies.get('exp') ? [{ label: <><Icon icon='faUpload' /><label className='ml-2'>Upload</label></>, name: 'upload' }] : []
        setTopOptions([...defaults, ...upload])
    }, [])

    useEffect(() => {
        const fulls = browser.name === 'chrome' ? [{ label: <><Icon icon={fullscreen ? 'faCompressArrowsAlt' : 'faExpandArrowsAlt'} /><label className='ml-2'>{fullscreen ? 'Normal Screen' : 'Full Screen'}</label></>, name: 'fullscreen' }] : []
        setBottomOptions([...fulls, ...defaultBottomOptions])
    }, [fullscreen])
    
    useEffect(() => {
        if (size.width < 400) {
            setTitle('Aramco Trading')
        } else {
            setTitle('Aramco Trading Analytics')
        }
    }, [size])

    const onCallback = event => {
        if (event.name === 'logout') {
            return logout()
        } else if (event.name === 'fullscreen') {
            toggleFullScreen()
        } else if (event.name === 'change_password') {
            changePassword()
        }  else {
            history.push('/' + event.name)
        }
    }

    const toDashboard = () => {
        history.push('/')
    }

    const whatsapp = () => {
        window.open(process.env.REACT_APP_WHATSAPP_LINK, "_blank") 
    }

    const openEmail = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleChange = event => {
        setMessage(event.target.value)
    }
    const onConfirm = async () => {
        const text = message.trim().replaceAll('\t', ' ').replaceAll('\n', ' ').replaceAll('\r', ' ')
        dispatch({ type: 'spin on' })
        await post2('user/email', { text })
        dispatch({ type: 'spin off' })
        setMessage('')
        handleClose()
    }
    const onCloseChange = event => {
        setOpenChange(false)
    }
    const handleOldPassword = event => {
        setOldPassword(event.target.value)
    }
    const handlePassword = event => {
        setPassword(event.target.value)
    }
    const handlePasswordRepeat = event => {
        setPasswordRepeat(event.target.value)
    }
    const onConfirmChange = async () => {
        dispatch({ type: 'spin on' })
        const result = await post2('auth/change', { username: Cookies.get('username'), OldPassword, password })
        dispatch({ type: 'spin off' })
        setOpenChange(false)
        if (result === true) {
            messageRef.current.showSuccess('Change Success')
        } else {        
            messageRef.current.showError(result.error)
        }
    }


    const toggleFullScreen = () => {
        const doc = window.document
        const docEl = doc.documentElement
        const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
        const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
        if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
            requestFullScreen.call(docEl)
            setFullscreen(true)
        } else {
            cancelFullScreen.call(doc)
            setFullscreen(false)
        }
    }

    const changePassword = async () => {
        const result = await change_password()
        messageRef.current.showSuccess('Auth0 will send an email to you to reset password. It may take several hours or a day.')
        // setOpenChange(true)
    }

    return (
        <header className='header'>
            <Message ref={messageRef} />
            <label className='header__left'>{title}</label>
            <label className='header__right'>
                <label className='header__left__icon' onClick={toDashboard}><Icon icon='faHome' size='sm' /></label>
                <label className='header__left__icon' onClick={whatsapp}><Icon icon='faWhatsapp' size='sm' /></label>
                <label className='header__left__icon' onClick={openEmail}><Icon icon='faEnvelope' size='sm' /></label>
                <MenuComponent className='header__left__icon' label={<Icon icon='faBars' size='sm' />} options={options} topOptions={topOptions} bottomOptions={bottomOptions} callback={onCallback}></MenuComponent>
                <Modal open={open} onClose={handleClose} className='modal' closeAfterTransition 
                    BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                    <Fade in={open}>
                        <div className='modal__content' style={{ width: '90%', height: 330, maxWidth: 500 }} >
                            <div className='column p-2'>
                                <TextField label="Leave message to us" multiline rows={12} value={message} variant='outlined' onChange={handleChange} />
                                <div className='row-right mt-3'><Button variant='outlined' onClick={onConfirm} className='mt-2'>Send</Button ></div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </label>
            <Dialog onClose={onCloseChange} open={openChange}>
                <div className='column-center f-02 w-300 h-200'>
                    <div>
                        <TextField variant='outlined' type='password' placeholder='Password' className='w-95p' onChange={handleOldPassword} />
                    </div>
                    <div>
                        <TextField variant='outlined' type='password' placeholder='Password' className='w-95p' onChange={handlePassword} />
                    </div>
                    <div>
                        <TextField variant='outlined' type='password' placeholder='Password' className='w-95p' onChange={handlePasswordRepeat} />
                    </div>
                </div>
                <div className='row-between px-3 my-3'>
                    <Button onClick={onCloseChange}>Cancel</Button>
                    <Button onClick={onConfirmChange}><label className='dark_blue bold-5'>Change</label></Button>
                </div>
            </Dialog>
        </header>
    )
}